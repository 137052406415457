<template>
  <div class="main-login-block center-block">
    <div>
        <div class="logo" v-html="Logotype"></div>
        <h3 class="title">Password reset</h3>

        <div class="form-row">
          <div class="w-100">
            <input-text v-model="login"/>
            <div class="error">{{errorLogin}}</div>
          </div>
        </div>
        
        <div class="form-row" v-if="response">
          <div class="error server">
            {{response}}
          </div>
        </div>

        <div class="form-row">
          <div class="main-button" style="width:100%" @click="onReset">Reset my password</div>
        </div>

    </div>
  </div>
  <alert-yes-no v-if="isVisibleConfirmAlert" title="Password reset sent" :text="confirmAlertHTML" :alertResult="closeModal" :hideYes="false" :hideNo="true"/>

</template>

<script>
import { Logotype } from '@/utils/icons'
import InputText from '@/components/inputs/InputText'
import { urls } from '@/utils/urls.js' 
import AlertYesNo from '@/components/AlertYesNo.vue'

export default {
  name: 'ResetPassword',
  components: { InputText, AlertYesNo },
  
  data() {
    return {
      isVisibleConfirmAlert: false,
      confirmAlertHTML: `
      <div>We’ve emailed you instructions for setting your password, if an account exists with the email you entered. You should receive them shortly.</div>
      <br>
      <div>If you don’t receive an email, please make sure you’ve entered the address you registered with, and check your spam folder.</div>
      `,
      Logotype,
      login: {
          placeholder: 'Enter your Email address here',
          value: null,
          isValid: false,
          clearable: false,
          required: true,
          readonly: false,
          regexp: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          errorKey: '',
          maxlength: 255,
          //minlength: 1
      },

      isDataLoaded: true,
      errorLogin: null,
      response: null
    }
  },

  computed: {
    isValidForm() {
      if(this.login.isValid) return true
      return false
    }
  },

  methods: {
    closeModal() {
      this.isVisibleConfirmAlert = false
    },

    async fetchReset(query) {
      this.response = ""
      if(!query  || !this.isDataLoaded) return
      this.isDataLoaded = false

      try {
        const response = await fetch(urls.usersResetPassword + '?' + query, {
          method: 'GET', 
          headers: {
            'accept': 'application/json'
            },
        })

        const json = await response.json()
        if(response.status === 200) {
          if(json === 'Ok') {
            this.isVisibleConfirmAlert = true
            this.login.value = null
          }
          else this.response = json

        } 
        else if (response.status > 399) {
          if(json?.detail) {
            this.response = json?.detail
          }
        }
        this.isDataLoaded = true

      } catch (error) {
        this.isDataLoaded = true
        console.log(error)
      }
  },

    onReset() {
      this.showErrors()
      if(this.isValidForm) {
        this.fetchReset(`email=${this.login.value}`)
      }
    },

    showErrors() {
        if(!this.isValidForm) {
          if(this.login.errorKey === 'required') this.errorLogin = 'Please enter your email'    
          else this.errorLogin = 'The email address you entered is invalid'
        }
        else this.errorLogin = null
    }
  }
}
</script>
